import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import Arrow from "../images/arrow-right.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const CardsWrapper = styled.div`
  margin-bottom: 7rem;
  scroll-margin-top: 2rem;
  h3 {
    text-align: center;
    display: block;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  > div {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 2rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 5rem;
    > div {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 2rem;
    > div {
      flex-direction: column-reverse;
      padding: 0 1rem;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }
  }
  @media (max-width: 575px) {
    h3 {
      text-align: left;
      padding: 0 1rem;
    }
  }
`;

const SingleCard = styled.div`
  width: 30%;
  border-radius: 2rem;
  transition: all 0.2s ease-out;
  background: transparent;
  padding: 2rem 1.5rem;
  border: 1px solid #1d5259;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  h5 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 3rem;
  }
  span {
    font-size: 2.25rem;
    font-weight: 600;
    margin: auto 0 3rem;
    display: block;
    color: #1d5259;
    letter-spacing: 1px;
    line-height: 1em;
    small {
      font-size: 1.375rem;
      line-height: 1em;
    }
  }
  button {
    outline: none;
    border: none;
    box-shadow: none;
    background: transparent;
    font-size: 1rem;
    font-weight: 600;
    margin-top: auto;
    cursor: pointer;
    position: relative;
    padding: 0 1.5rem 0 0;
    color: #000;
    &:after {
      content: "";
      right: 0;
      top: 50%;
      transform: translateY(-45%);
      width: 1.25rem;
      height: 1rem;
      position: absolute;
      background: url(${Arrow}) no-repeat center center;
      background-size: contain;
      transition: all 0.2s ease-out;
    }
    &:hover {
      &:after {
        transform: translateY(-50%) translateX(0.5rem);
      }
    }
  }
  @media (max-width: 991px) {
    width: calc(50% - 1rem);
    margin-bottom: 2rem;
    &:nth-child(1) {
      margin-right: 2rem;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 2rem;
    &:nth-child(1) {
      margin-right: 0;
    }
  }
`;

export default function Pricing() {
  useEffect(() => {
    const pricingWrapper = document.querySelector("#pricing");

    gsap.fromTo(
      pricingWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: pricingWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <CardsWrapper id="pricing">
      <h3>Cennik</h3>
      <div>
        <SingleCard>
          <h5>Psychoterapia indywidualna</h5>
          <span>100zł - 150zł</span>
          <button onClick={() => scrollTo("#contact")}>Skontaktuj się</button>
        </SingleCard>
        <SingleCard>
          <h5>Psychoterapia grupowa</h5>
          <span>
            <small>Cena ustalana indywidualnie</small>
          </span>
          <button onClick={() => scrollTo("#contact")}>Skontaktuj się</button>
        </SingleCard>
        <SingleCard>
          <h5>Konsultacja online</h5>
          <span>100zł - 150zł</span>
          <button onClick={() => scrollTo("#contact")}>Skontaktuj się</button>
        </SingleCard>
      </div>
    </CardsWrapper>
  );
}
