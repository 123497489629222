import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import Arrow from "../images/arrow-right.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const NumbersWrapper = styled.div`
  margin-bottom: 6rem;
  scroll-margin-top: 2rem;
  background: rgba(179, 218, 214, 1);
  padding: 4rem 0;
  h3 {
    text-align: center;
    display: block;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  > div {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 2rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 4rem;
    > div {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 767px) {
    padding: 3rem 0;

    > div {
      flex-direction: column-reverse;
      padding: 0 1rem;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }
  }
`;

const SingleCard = styled.div`
  width: 30%;
  border-radius: 1rem;
  transition: all 0.2s ease-out;
  background: transparent;
  padding: 2rem 1.5rem;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  text-align: center;
  h5 {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  span {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 2rem;
    display: block;
    color: #1d5259;
    letter-spacing: 1px;
    line-height: 1em;
    small {
      font-size: 1.375rem;
      line-height: 1em;
    }
  }
  p {
    font-size: 1rem;
  }
  @media (max-width: 991px) {
    width: calc(50% - 1rem);
    margin-bottom: 2rem;
    &:nth-child(1) {
      margin-right: 2rem;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 1.5rem;

    &:nth-child(1) {
      margin-right: 0;
    }
    h5 {
      font-size: 3rem;
    }
    span {
      margin: 0 0 1.5rem;
    }
  }
`;

export default function Numbers() {
  useEffect(() => {
    const numbersWrapper = document.querySelector("#numbers");

    gsap.fromTo(
      numbersWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: numbersWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <NumbersWrapper id="numbers">
      <h3>Możesz mi zaufać</h3>
      <div>
        <SingleCard>
          <h5>10+</h5>
          <span>lat doświadczenia</span>
          <p>
            Ponad 10 lat doświadczenia zawodowego w praktyce psychologicznej i
            psychoterapeutycznej.
          </p>
        </SingleCard>
        <SingleCard>
          <h5>5</h5>
          <span>Ukończonych szkół</span>
          <p>Moje wykształcenie stanowi solidną podstawę do pomagania innym.</p>
        </SingleCard>
        <SingleCard>
          <h5>7</h5>
          <span>Ukończonych kursów/szkoleń</span>
          <p>
            Wszystkie kursy oraz szkolenia skutecznie rozwinęły mnie w tym, w
            czym jestem najlepsza.
          </p>
        </SingleCard>
      </div>
    </NumbersWrapper>
  );
}
