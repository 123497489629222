import React from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";

const StyledHero = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 12rem 2rem 0;
  margin-bottom: 3rem;
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: 60vw;
    max-width: 1800px;
    background: rgb(21, 154, 173);
    background: linear-gradient(
      0deg,
      rgba(21, 154, 173, 1) 0%,
      rgba(179, 218, 214, 1) 100%
    );
    height: 100%;
  }
  > div {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    width: 100%;
  }
  .gatsby-image-wrapper {
    position: absolute;
    right: -3rem;
    bottom: 2rem;
    width: 77vw;
    height: 100vh;
    z-index: 4;
    max-width: 1500px;
    img {
      width: 100%;
      height: auto;
      margin-top: auto;
    }
  }
  h1 {
    font-size: 4.5rem;
    margin-bottom: 3.5rem;
    line-height: 1.15em;
    position: relative;
    z-index: 4;
    font-weight: 700;
    color: #000;
  }
  p {
    margin-bottom: 2rem;
    max-width: 500px;
    position: relative;
    z-index: 4;
    color: #000;
    font-size: 1.5rem;
  }
  .phone-fixed {
    position: fixed;
    right: 0.75rem;
    bottom: 0.75rem;
    z-index: 999;
    transition: all 1s ease-out;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    .gatsby-image-wrapper {
      position: static;
      width: auto;
      height: auto;
      max-width: none;
      img {
        width: auto;
      }
      picture {
        min-width: auto;
      }
    }
    &:hover {
      transform: scale(1.15);
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(0.9);
    }
  }
  @media (max-width: 1199px) {
    h1 {
      margin-bottom: 3rem;
      font-size: 3.75rem;
    }
    p {
      font-size: 1.35rem;
      max-width: 400px;
    }
  }
  @media (max-width: 991px) {
    h1 {
      font-size: 3rem;
      margin-bottom: 2.5rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
  @media (max-width: 767px) {
    padding: 0 1rem;
    margin-bottom: 1rem;
    min-height: auto;
    &:after {
      display: none;
    }
    > div {
      flex-direction: column-reverse;
      &:first-child {
        padding-top: 4rem;
      }
    }
    .gatsby-image-wrapper {
      position: static;
      height: auto;
      z-index: 4;
      display: flex;
      justify-content: flex-end;
      width: calc(100% + 2rem);
      margin-left: -1rem;
      img {
        margin: 0;
        position: static;
      }
      picture {
        min-width: 100%;
      }
    }
    h1 {
      font-size: 2.25rem;
    }

    h1,
    p {
      max-width: 500px;
      width: 100%;
      br {
        display: none;
      }
    }
  }
  @media (max-width: 575px) {
    p {
      font-size: 1.125rem;
    }
  }
  @media (max-width: 360px) {
    > div {
      &:first-child {
        padding-top: 5.5rem;
      }
    }
  }
  @media (max-width: 340px) {
    h1 {
      font-size: 1.85rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  width: 40%;
  margin: 0 auto 0 0 !important;
  @media (max-width: 1199px) {
    width: 50%;
  }
  @media (max-width: 991px) {
    width: 55%;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 4rem 0 2rem 0;
  }
  @media (max-width: 575px) {
    padding: 3rem 0 1rem 0;
  }
`;

export default function Hero() {
  return (
    <StyledHero>
      <div>
        <StaticImage
          src="../../static/psychologist-hero.svg"
          alt="Główne zdjęcie"
          placeholder={"none"}
          layout={"fullWidth"}
          loading={"eager"}
        />
        <StyledLeft id="textWrapper">
          <h1>
            Potrzebujesz pomocy <br /> po stracie?
          </h1>
          <p>
            Pomogę Ci zrozumieć świat i Twoje otoczenie, które po Twojej
            tragedii kompletnie Cię nie rozumie.
          </p>
          <button className="button" onClick={() => scrollTo("#about")}>
            Dowiedz się więcej
          </button>
        </StyledLeft>
      </div>
      <a className="phone-fixed" href="tel:+48516015162">
        <StaticImage
          src="../../static/phone-fixed.svg"
          alt="Ikonka telefonu"
          placeholder={"none"}
          height={"40"}
          width={"40"}
          loading={"eager"}
        />
      </a>
    </StyledHero>
  );
}
