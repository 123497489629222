import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HoursWrapper = styled.div`
  margin-bottom: 6rem;
  h4 {
    display: block;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  .place-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0;
    border: 1px solid rgb(29, 82, 89);
    margin: 0 0 2rem 0;
    border-radius: 1rem;
    div {
      margin: 0;
    }
    .left-col {
      border-right: 1px solid rgb(29, 82, 89);
      padding: 2rem;

      li {
        font-size: 1.125rem;
      }
    }
    ul {
      list-style: disc;
      padding-left: 1rem;
    }
    .right-col {
      padding: 2rem;
    }
    h5 {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }
  }
  div {
    max-width: 1400px;
    padding: 0 1rem 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    strong {
      font-weight: 800;
    }
  }
  .hours-wrapper {
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 1.25rem;
    color: #000;
    line-height: 1.3em;
    margin: 0 0 3rem;
  }
  ul {
    padding: 0;
  }
  li {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    span {
      padding-left: 0.5rem;
      font-weight: 600;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 4rem;
    p,
    li {
      font-size: 1.25rem;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    h4 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }

    p,
    li {
      font-size: 1rem;
    }
    li {
      margin-bottom: 0.5rem;
    }
  }
  @media (max-width: 575px) {
    .hours-wrapper {
      justify-content: flex-start;
      align-items: flex-start;
    }
    .place-wrapper {
      .left-col {
        padding: 1rem;
        li {
          font-size: 0.875rem;
        }
      }
      .right-col {
        padding: 0.875rem;
      }
      h5 {
        margin-bottom: 1.25rem;
        font-size: 1rem;
      }
    }
    p {
      text-align: center;
    }
  }
`;

export default function Hours() {
  useEffect(() => {
    const hoursWrapper = document.querySelector("#hours");

    gsap.fromTo(
      hoursWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: hoursWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <HoursWrapper id="hours">
      <div>
        <h4>Miejsce przyjęć</h4>
        <div className="place-wrapper">
          <div className="left-col">
            <h5>Wizyty w gabinetach po konsultacji telefonicznej:</h5>
            <ul>
              <li>Długołęka - w kierunku na Milicz</li>
              <li>Św. Macieja 7a we Wrocławiu</li>
            </ul>
          </div>
          <div className="right-col">
            <h5>Wizyty online</h5>
          </div>
        </div>
        <p>
          Godziny są dostosowane indywidualnie, głównie popołudniami i
          wieczorami lub weekendami. <br /> Cena konsultacji:{" "}
          <strong>100-150zł</strong> w zależności od formy i czasu.
        </p>
        <h4>Godziny przyjęć</h4>
        <div className="hours-wrapper">
          <ul>
            <li>
              Poniedziałki:{" "}
              <span>
                w gabinecie po wcześniejszym umówieniu lub online 15:30-22:00
              </span>
            </li>
            <li>
              Wtorki: <span>brak</span>
            </li>

            <li>
              Środy: <span>online 19:00 - 22:00</span>
            </li>

            <li>
              Czwartki: <span>od 16:00 do 22:00</span>
            </li>

            <li>
              Piątki: <span>od 16:00 do 22:00</span>
            </li>

            <li>
              Soboty: <span>od 11:00 do 22:00</span>
            </li>

            <li>
              Niedziele: <span>od 11:00 do 22:00</span>
            </li>
          </ul>
        </div>
      </div>
    </HoursWrapper>
  );
}
