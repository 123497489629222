import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import About from "../components/About";
import AboutSecond from "../components/AboutSecond";
import History from "../components/History";
import Family from "../components/Family";
import Science from "../components/Science";
import School from "../components/School";
import Helping from "../components/Helping";
import Cards from "../components/Cards";
import Info from "../components/Info";
import Pricing from "../components/Pricing";
import Numbers from "../components/Numbers";
import Hours from "../components/Hours";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const StyledContainer = styled.section`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  > .gatsby-image-wrapper {
    &:first-child {
      height: 100%;
      width: 70%;
      z-index: 3;
    }
  }

  @media (max-width: 1399px) {
    > .gatsby-image-wrapper {
      &:first-child {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    overflow: hidden;
    > .gatsby-image-wrapper {
      &:first-child {
        width: 125%;
      }
    }
  }
  @media (max-width: 575px) {
    overflow: hidden;
    > .gatsby-image-wrapper {
      &:first-child {
        width: 100%;
      }
    }
  }
`;

export default function Home({ data }) {
  return (
    <Layout>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Olga Jędrzejewska</title>
        <meta
          name="title"
          content="Jestem psychologiem i chcę Ci pomóc po stracie"
        />
        <meta
          name="description"
          content="Jestem psychologiem i spotkanie ze mną ma na celu wstępne sprecyzowanie, nazwanie i
            określenie trudności, z jakimi boryka się osoba po stracie. 
            Dzięki temu możliwe jest wskazanie odpowiedniej formy pomocy:
            psychoterapię indywidualną lub grupową, bądź konsultację
            psychiatryczną. Konsultacja może być jednorazowa lub w formie kilku
            spotkań czy terapii grupowej."
        />{" "}
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://olgajedrzejewska.pl/" />
        <meta
          property="og:title"
          content="Jestem psychologiem i chcę Ci pomóc po stracie"
        />
        <meta
          property="og:description"
          content="Jestem psychologiem i spotkanie ze mną ma na celu wstępne sprecyzowanie, nazwanie i
            określenie trudności, z jakimi boryka się osoba po stracie. 
            Dzięki temu możliwe jest wskazanie odpowiedniej formy pomocy:
            psychoterapię indywidualną lub grupową, bądź konsultację
            psychiatryczną. Konsultacja może być jednorazowa lub w formie kilku
            spotkań czy terapii grupowej."
        />
        <meta
          property="og:image"
          content="https://olgajedrzejewska.pl/logo.png"
        />{" "}
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://olgajedrzejewska.pl/" />
        <meta
          property="twitter:title"
          content="Jestem psychologiem i chcę Ci pomóc po stracie"
        />
        <meta
          property="twitter:description"
          content="Jestem psychologiem i spotkanie ze mną ma na celu wstępne sprecyzowanie, nazwanie i
            określenie trudności, z jakimi boryka się osoba po stracie. 
            Dzięki temu możliwe jest wskazanie odpowiedniej formy pomocy:
            psychoterapię indywidualną lub grupową, bądź konsultację
            psychiatryczną. Konsultacja może być jednorazowa lub w formie kilku
            spotkań czy terapii grupowej."
        />
        <meta
          property="twitter:image"
          content="https://olgajedrzejewska.pl/logo.png"
        />
      </Helmet>
      <Hero />
      <Info />
      <About />
      <AboutSecond />
      <History />
      <Family />
      <Science />
      <School />
      <Cards />
      <Helping />
      <Numbers />
      <Hours />
      <Pricing />
      <Contact />
      <Footer />
    </Layout>
  );
}
