import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledHelping = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 8rem;
  scroll-margin-top: 2rem;

  > div {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .gatsby-image-wrapper {
    width: 45%;
    img {
      width: 100%;
      height: auto;
      margin-top: auto;
    }
  }
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  p {
    margin-bottom: 2rem;
    position: relative;
    z-index: 4;
    color: #000;
    font-size: 1.25rem;
  }
  @media (max-width: 1199px) {
    h2 {
      margin-bottom: 1.5rem;
      font-size: 2rem;
    }
    p {
      font-size: 1.125rem;
    }
  }
  @media (max-width: 991px) {
    h1 {
      font-size: 1.75rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media (max-width: 767px) {
    padding: 0 1rem;
    margin-bottom: 3rem;
    > div {
      flex-direction: column;
    }
    .gatsby-image-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      img {
        margin: 0;
        position: static;
        max-width: 85%;
      }
      picture {
        min-width: 100%;
        text-align: center;
      }
    }
    h1,
    p {
      max-width: 500px;
      width: 100%;
    }
  }
  @media (max-width: 575px) {
    /* h1 {
      font-size: 2rem;
    } */
    p {
      font-size: 1.125rem;
    }
  }
  @media (max-width: 360px) {
    > div {
    }
  }
  @media (max-width: 340px) {
    h1 {
      font-size: 1.85rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  width: 55%;
  margin: 0 auto 0 0 !important;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 0 4rem 0;
  }
`;

export default function Helping() {
  useEffect(() => {
    const helpingWrapper = document.querySelector("#helping");

    gsap.fromTo(
      helpingWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: helpingWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <StyledHelping id="helping">
      <div>
        <StyledLeft id="textWrapper">
          <p>
            Nikt inny nie jest w stanie zrozumieć i współodczuwać Twojego bólu,
            rozpaczy i cierpienia tak jak ja.
            <br />
            Po ponad 13 latach po stracie nauczyłam się dalej żyć i funkcjonować
            w społeczeństwie oraz pomagać tym, którzy tej pomocy szukają.
            <br />
            Pokażę Ci, jak się zachowywać i funkcjonować wobec osób, które Mówią
            Ci, że nic się nie stało, a Ty musisz żyć dalej, bo jesteś młoda i
            będziesz jeszcze mieć dzieci..
          </p>
          <button className="button" onClick={() => scrollTo("#contact")}>
            Skontaktuj się
          </button>
        </StyledLeft>
        <StaticImage
          src="../../static/helping.svg"
          alt="O mnie"
          layout={"fullWidth"}
          loading={"lazy"}
          placeholder={"none"}
        />
      </div>
    </StyledHelping>
  );
}
