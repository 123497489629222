import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledAbout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 4rem;
  scroll-margin-top: 2rem;

  > div {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .gatsby-image-wrapper {
    width: 50%;
    img {
      width: 100%;
      height: auto;
      margin-top: auto;
    }
  }
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  p {
    margin-bottom: 2rem;
    position: relative;
    z-index: 4;
    color: #000;
    font-size: 1.25rem;
  }
  @media (max-width: 1199px) {
    h2 {
      margin-bottom: 2.5rem;
      font-size: 2rem;
    }
    p {
      font-size: 1.125rem;
    }
  }
  @media (max-width: 991px) {
    h1 {
      font-size: 1.75rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media (max-width: 767px) {
    padding: 0 1rem;
    margin-bottom: 3rem;
    > div {
      flex-direction: column-reverse;
    }
    .gatsby-image-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      img {
        margin: 0;
        position: static;
      }
      picture {
        min-width: 100%;
      }
    }
    h1,
    p {
      max-width: 500px;
      width: 100%;
    }
  }
  @media (max-width: 575px) {
    /* h1 {
      font-size: 2rem;
    } */
    p {
      font-size: 1.125rem;
    }
  }
  @media (max-width: 360px) {
    > div {
    }
  }
  @media (max-width: 340px) {
    h1 {
      font-size: 1.85rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  width: 50%;
  margin: 0 auto 0 0 !important;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 0 2rem 0;
  }
`;

export default function About() {
  useEffect(() => {
    const aboutWrapper = document.querySelector("#about");

    gsap.fromTo(
      aboutWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: aboutWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <StyledAbout id="about">
      <div>
        <StaticImage
          src="../../static/about.svg"
          alt="O mnie"
          layout={"fullWidth"}
          placeholder={"none"}
          loading={"lazy"}
        />
        <StyledRight id="textWrapper">
          <h2>Jestem psychologiem</h2>
          <p>
            Spotkanie ze mną ma na celu wstępne sprecyzowanie, nazwanie i
            określenie trudności, z jakimi boryka się osoba po stracie. <br />{" "}
            Dzięki temu możliwe jest wskazanie odpowiedniej formy pomocy:
            psychoterapię indywidualną lub grupową, bądź konsultację
            psychiatryczną. Konsultacja może być jednorazowa lub w formie kilku
            spotkań czy terapii grupowej. Psycholog to osoba, która ukończyła
            studia wyższe na kierunku psychologia i otrzymała dyplom ukończenia
            studiów magisterskich. <br />
            Psycholog nie ma uprawnień do wypisywania zwolnień L4 czy
            wystawiania recept.
          </p>
          <button className="button" onClick={() => scrollTo("#aboutsecond")}>
            Czytaj więcej
          </button>
        </StyledRight>
      </div>
    </StyledAbout>
  );
}
