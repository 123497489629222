import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ScienceWrapper = styled.div`
  margin-bottom: 6rem;
  h4 {
    text-align: center;
    display: block;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  div {
    max-width: 1400px;
    padding: 0 1rem 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    strong {
      font-weight: 800;
      font-size: 1.25rem;
    }
  }

  p {
    margin-bottom: 1rem;
    position: relative;
    z-index: 4;
    color: #000;
    font-size: 1rem;
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
    margin-bottom: 2rem;
  }
  li {
    margin-bottom: 0.375rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 4rem;
    p,
    li {
      font-size: 0.9375rem;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    h4 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }
  }
  @media (max-width: 575px) {
    h4 {
      text-align: left;
    }
    .hours-wrapper {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;

export default function Science() {
  useEffect(() => {
    const scienceWrapper = document.querySelector("#science");

    gsap.fromTo(
      scienceWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: scienceWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <ScienceWrapper id="science">
      <div>
        <h4>Prace naukowe</h4>
        <p>
          <strong>Moje najważniejsze konferencje i publikacje:</strong>
        </p>
        <ul>
          <li>
            2012r -Prelegentka na XXI Piekarskim Sympozjum Naukowym poświęconym
            zagadnieniu: DOM, W KTÓRYM RODZI SIĘ WSPÓLNOTA – RODZINA,
            SPOŁECZEŃSTWO, KOŚCIÓŁ
          </li>
          <li>
            2012r - Autorka artykułu naukowego „Rodzina po stracie” Studia i
            Materiały Wydziału Teologicznego Uniwersytetu Śląskiego w Katowicach
            „Jaka rodzina takie społeczeństwo – Wspólnotwórczy wymiar wychowania
            integralnego” str. 203-224 Księgarnia św. Jacka
          </li>
          <li>
            2013r - Współautorka Dziennika Jakości Grup Wsparcia stworzonego na
            potrzeby Fundacji „By Dalej Iść”
          </li>
          <li>
            2013r - Współautorka programu Szkoleń „Pomoc w sytuacji straty
            dziecka. Interwencja kryzysowa. Śmierć i proces żałoby” stworzonego
            na potrzeby Fundacji „By Dalej Iść”
          </li>
          <li>
            2013r - Laureatka statuetki „Łabędzia” 2012 jako Przyjaciel Rodziny
            zorganizowanego przez Fundację Razem Lepiej w kategorii: „Przekaz
            medialny / edukacyjny”
          </li>
          <li>
            2013r - Współpraca z portalem wieszjak.pl i tworzenie dla portalu
            artykułów dotyczących straty i żałoby.{" "}
          </li>
          <li>
            2014r - Konferencja z okazji Dnia Świętości Życia pt. „Żyją w Bogu”
            - w trosce o dzieci zmarłe przed narodzeniem i ich rodziców. Płońsk
          </li>
          <li>
            2014r - Prelegentka i prowadząca warsztaty na IX Międzynarodowej
            Konferencji Naukowo-Szkoleniowa Życiodajna Śmierć - Pamięci
            Elizabeth Kübler-Ross. Białystok
          </li>
          <li>
            2014r - "Trauma - rodzice po starcie dziecka poronionego lub martwo
            narodzonego. Strata dziecka widziana oczami rodzica" W drodze do
            brzegu życia - tom XII Red. Krajewska-Kułak Elżbieta, Łukaszuk
            Cecylia, Lewko Jolanta, Kułak Wojciech
          </li>
          <li>
            2014r - I Ogólnopolska Interdyscyplinarna Konferencja Naukowa
            "Utrata i żałoba - Teoria i praktyka" Katowice – udział bierny
          </li>
          <li>
            2014r - Prelegentka na I Międzynarodowej Konferencji Naukowej
            „Bezpieczeństwo Zdrowotne – ujęcie interdyscyplinarne”
          </li>
          <li>
            2012-2019 Autorka wielu publikacji naukowych w wielu poradnikach,
            książkach i wydawnictwach
          </li>
        </ul>
      </div>
    </ScienceWrapper>
  );
}
