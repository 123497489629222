import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const CardsWrapper = styled.div`
  margin-bottom: 7rem;
  scroll-margin-top: 2rem;
  h3 {
    text-align: center;
    display: block;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  > div {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 2rem;
  }
  @media (max-width: 991px) {
    margin-bottom: 5rem;
    > div {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 4rem;
    > div {
      flex-direction: column-reverse;
      padding: 0 1rem;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }
  }
  @media (max-width: 575px) {
    h3 {
      text-align: left;
      padding: 0 1rem;
    }
  }
`;

const SingleCard = styled.div`
  width: 30%;
  border-radius: 2rem;
  transition: all 0.2s ease-out;
  background: transparent;
  padding: 2rem 1.5rem;
  background-color: rgba(179, 218, 214, 1);
  h5 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: 991px) {
    width: calc(50% - 1rem);
    margin-bottom: 2rem;
    &:nth-child(1) {
      margin-right: 2rem;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 2rem;
    &:nth-child(1) {
      margin-right: 0;
    }
  }
`;

export default function Cards() {
  useEffect(() => {
    const cardsWrapper = document.querySelector("#cards");

    gsap.fromTo(
      cardsWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: cardsWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <CardsWrapper id="cards">
      <h3>Oferta</h3>
      <div>
        <SingleCard>
          <h5>Konsultacja online</h5>
          <p>
            Jest to rozmowa online z psychologiem, której głównym celem jest
            polepszenie stanu psychicznego pacjenta. Taka konsultacja u
            psychiatry to forma troski o swoje zdrowie, o której warto pomyśleć
            zawsze wtedy, gdy obserwujemy u siebie pogorszenie funkcjonowania,
            spadek sił do działania i poczucie, że to, czego doświadczamy,
            zaczyna nas przerastać.
          </p>
        </SingleCard>
        <SingleCard>
          <h5>Psychoterapia grupowa</h5>
          <p>
            Sprawia, że terapeuta schodzi na dalszy plan. Główny nacisk
            kładziony jest na związek pomiędzy członkami grupy. Zasadą
            psychoterapii grupowej jest równość – równo każdego członka traktuje
            psychoterapeuta, równość panuje także pomiędzy poszczególnymi
            członkami grupy.
          </p>
        </SingleCard>
        <SingleCard>
          <h5>Psychoterapia indywidualna</h5>
          <p>
            Psychoterapia indywidualna pozwala na pełne skupienie się
            psychoterapeuty na pacjencie. W centrum tego typu psychoterapii stoi
            pacjent i wszystko to, co go dotyczy - jego uczucia, przeżycia,
            przemyślenia, nastawienie, czy sposób w jaki widzi samego siebie.
          </p>
        </SingleCard>
      </div>
    </CardsWrapper>
  );
}
