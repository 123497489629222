import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const InfoWrapper = styled.div`
  margin-bottom: 0;
  /* background: linear-gradient(
    0deg,
    rgb(13, 130, 147) 0%,
    rgb(134, 193, 187) 100%
  ); */

  .inside {
    max-width: 1400px;
    min-height: 25rem;
    padding: 0 2rem 2rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    strong {
      font-weight: 800;
    }
  }
  .gatsby-image-wrapper {
    position: absolute;
    z-index: -1;
    left: 1rem;
    top: 4rem;
    opacity: 0.075;
  }
  p {
    font-size: 1.5rem;
    color: #000;
    margin: 0 auto 1rem;
    text-align: center;
    line-height: 1.3em;
    font-style: italic;
  }
  span {
    align-self: flex-end;
    color: #000;
    font-size: 1.125rem;
  }

  @media (max-width: 991px) {
    .inside {
      min-height: 20rem;
    }
    .gatsby-image-wrapper {
      top: 2rem;
    }
    p {
      font-size: 1.25rem;
    }
    span {
      font-size: 1rem;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    .gatsby-image-wrapper {
      top: 1rem;
    }
    .inside {
      padding: 2rem 1rem;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }
  }
`;

export default function Info() {
  useEffect(() => {
    const infoWrapper = document.querySelector(".info-wrapper");

    gsap.fromTo(
      infoWrapper,
      { y: "+=150", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.8,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: infoWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <InfoWrapper className="info-wrapper">
      <div className="inside">
        <p>
          "Spojrzenie na stratę nie tylko z punktu widzenia psychologa ale
          przede wszystkim jako <strong>rodzic po stracie</strong> - który przez
          pewien czas nie mógł znaleźć odpowiedniej pomocy - wiem, jak jest Ci
          ciężko z odpowiednim wyborem."
        </p>
        <span>~ Olga Jędrzejewska</span>
        <StaticImage
          src="../../static/quote.svg"
          alt="Ikonka cytatu"
          placeholder={"none"}
          loading={"eager"}
          height={"150"}
          width={"150"}
        />
      </div>
    </InfoWrapper>
  );
}
