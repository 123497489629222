import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const SchoolWrapper = styled.div`
  margin-bottom: 6rem;
  h4 {
    text-align: center;
    display: block;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  div {
    max-width: 1400px;
    padding: 0 1rem 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    strong {
      font-weight: 800;
      font-size: 1.25rem;
    }
  }

  p {
    margin-bottom: 1rem;
    position: relative;
    z-index: 4;
    color: #000;
    font-size: 1rem;
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
    margin-bottom: 2rem;
  }
  li {
    margin-bottom: 0.375rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 4rem;
    p,
    li {
      font-size: 0.9375rem;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    h4 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }
  }
  @media (max-width: 575px) {
    h4 {
      text-align: left;
    }
    .hours-wrapper {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;

export default function School() {
  useEffect(() => {
    const schoolWrapper = document.querySelector("#school");

    gsap.fromTo(
      schoolWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: schoolWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <SchoolWrapper id="school">
      <div>
        <h4>Wykształcenie i doświadczenie</h4>
        <p>
          <strong>Ukończone szkoły:</strong>
        </p>
        <ul>
          <li>
            Akademia Pedagogicznaim. Jana Długosza w Częstochowiekierunek:
            Pedagogika Ogólnaspecjalność: Praca Socjalna
          </li>
          <li>
            Szkoła Wyższa Psychologii Społecznejwe WrocławiuStudia Podyplomowe
            Specjalność: Psychologia śledcza w praktyce
          </li>
          <li>
            Szkoła Wyższa Psychologii Społecznejwe WrocławiuPsychologia dla
            magistrów – II-gi Fakultet – Indywidualny tok nauczania Kierunek:
            PsychologiaSpecjalność: Psychologia kliniczna{" "}
          </li>
          <li>
            Szkoła Wyższa Psychologii Społecznej we Wrocławiu Studia
            doktoranckie Promotor prof.J.Kurzępa{" "}
          </li>
        </ul>
        <p>
          <strong>Ukończone kursy:</strong>
        </p>
        <ul>
          <li>
            2003r - Udział w programie treningowym P.E.A.Ce. / Warsztaty Analizy
            Potencjału Zawodowego/
          </li>
          <li>
            2008r - Szkolenie z podstaw pomocy psychologicznej w praktyce
            INWEDRO
          </li>
          <li>2011r - Interwencja kryzysowa w śmierci i żałobie</li>
          <li>2011r - Zaburzenia osobowości - diagnoza i psychoterapia</li>
          <li>
            2012r - Grupy wsparcia, edukacyjne, terapeutyczne - organizowanie i
            prowadzenie
          </li>
          <li>2012r - Profesjonalna pomoc - podstawy pracy terapeutycznej</li>
          <li>2012r - Terapia systemowa rodzin - praca ze stratą i żałobą</li>
          <li>2014r - Intensywny kurs Interwencji Kryzysowej</li>
        </ul>
        <p>
          <strong>Doświadczenie:</strong>
        </p>
        <ul>
          <li>
            2010r – do nadal - Współpraca z drużyną niepełnosprawnych grających
            w koszykówkę na wózkach. Weekendowo 1 x w miesiącu
          </li>
          <li>
            2011r - 2012r - Praca w charakterze asysty zajęciowego z 12 latkiem
            z zespołem Aspergera we Wrocławiu
          </li>
          <li>
            2011r - 2018r - Współpraca z Fundacją „By Dalej Iść” jako moderator
            w grupach wsparcia i konsultacjach indywidualnych z rodzicami po
            stracie dziecka. Kilka razy w miesiącu w godzinach popołudniowo –
            wieczornych.
          </li>
          <li>
            2012r - 2013r - Opiekun Praktyk zawodowych studentów skierowanych do
            Fundacji „By Dalej Iść” przez dr Mariolę T.Kozubek - opiekuna
            praktyk zawodowych UŚ w Katowicach Wydział Teologiczny
          </li>
          <li>
            2013r - 2014r - Fundacja By Dalej iść - Prowadzenie szkoleń z
            zakresu żałoby, straty i interwencji kryzysowej.
          </li>
          <li>2016r – 2020r - Nauczyciel w Teb Edukacja z psychologii</li>
          <li>Obecnie praktyka prywatna</li>
        </ul>
      </div>
    </SchoolWrapper>
  );
}
