import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 6rem;
  scroll-margin-top: 2rem;
  > div {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .gatsby-image-wrapper {
    width: 50%;
    img {
      width: 100%;
      height: auto;
      margin-top: auto;
    }
  }
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  p {
    margin-bottom: 1rem;
    position: relative;
    z-index: 4;
    color: #000;
    font-size: 1rem;
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
    margin-bottom: 2rem;
  }
  li {
    margin-bottom: 0.375rem;
  }
  @media (max-width: 1199px) {
    h2 {
      margin-bottom: 2.5rem;
      font-size: 2rem;
    }
  }
  @media (max-width: 991px) {
    h1 {
      font-size: 1.75rem;
    }
    p,
    li {
      font-size: 0.9375rem;
    }
  }
  @media (max-width: 767px) {
    padding: 0 1rem;
    margin-bottom: 3rem;
    > div {
      flex-direction: column-reverse;
    }
    .gatsby-image-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      img {
        margin: 0;
        position: static;
      }
      picture {
        min-width: 100%;
      }
    }
    h1,
    p {
      width: 100%;
    }
  }

  @media (max-width: 360px) {
    > div {
    }
  }
  @media (max-width: 340px) {
    h1 {
      font-size: 1.85rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  width: 50%;
  margin: 0 auto 0 0 !important;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 0 2rem 0;
  }
`;

export default function AboutSecond() {
  useEffect(() => {
    const historyWrapper = document.querySelector("#history");

    gsap.fromTo(
      historyWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: historyWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <StyledHistory id="history">
      <div>
        <StaticImage
          src="../../static/history.svg"
          alt="Kim jestem?"
          layout={"fullWidth"}
          placeholder={"none"}
          loading={"lazy"}
        />
        <StyledRight id="textWrapper">
          <h2>Moja historia</h2>
          <p>
            W 2009 roku ukończyłam studia magisterskie na Akademii Jana Długosza
            w Częstochowie jako pracownik socjalny. W tym samym roku 11
            października pochowałam swoją córeczkę Nadię, która urodziła się
            1.08.2007 roku, a 1.09.2008 roku zachorowała na ostrą białaczkę
            limfoblastyczną T-komórkową wysokiego ryzyka. Córeczka przeszła
            silną chemioterapię, przeszczep szpiku a potem nawrót choroby, z
            którego już nie udało się jej wyjść.
          </p>
          <p>
            Po ponad rocznej walce córki z chorobą, która zakończyła się
            tragedią rodzinną, postanowiłam przepracować żałobę, aby móc dalej
            żyć. Niestety znalezienie odpowiedniego specjalisty graniczyło z
            cudem. Po prawidłowym przepracowaniu żałoby i nauczenia się żyć na
            nowo, postanowiłam rozpocząć aktywną formę pomocy dla takich samych
            rodziców jak ja – po stracie.
          </p>
          <p>
            Rozpoczęłam pracę w róznych Fundancjach i Instytucjach pomocówych,
            prowadząc Interwencje kryzysowe, szkolenia czy terapie grupowe.
          </p>
        </StyledRight>
      </div>
    </StyledHistory>
  );
}
