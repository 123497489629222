import React, { useEffect } from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/Global";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 6rem;
  scroll-margin-top: 2rem;
  > div {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .gatsby-image-wrapper {
    width: 50%;
    img {
      max-width: 100%;
      height: auto;
      max-height: 50rem;
      margin: auto;
    }
  }
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    line-height: 1.25em;
    position: relative;
    z-index: 4;
    font-weight: 600;
    color: #000;
  }
  p {
    margin-bottom: 1rem;
    position: relative;
    z-index: 4;
    color: #000;
    font-size: 1rem;
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
    margin-bottom: 2rem;
  }
  li {
    margin-bottom: 0.375rem;
  }
  @media (max-width: 1199px) {
    h2 {
      margin-bottom: 2.5rem;
      font-size: 2rem;
    }
  }
  @media (max-width: 991px) {
    h1 {
      font-size: 1.75rem;
    }
    p,
    li {
      font-size: 0.9375rem;
    }
  }
  @media (max-width: 767px) {
    padding: 0 1rem;
    margin-bottom: 3rem;
    > div {
      flex-direction: column;
    }
    .gatsby-image-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      img {
        margin: 0;
        position: static;
      }
      picture {
        min-width: 100%;
      }
    }
    h1,
    p {
      width: 100%;
    }
  }

  @media (max-width: 360px) {
    > div {
    }
  }
  @media (max-width: 340px) {
    h1 {
      font-size: 1.85rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  width: 50%;
  margin: 0 auto 0 0 !important;
  padding-right: 2rem;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 0 2rem 0;
  }
`;

export default function AboutSecond() {
  useEffect(() => {
    const aboutSecondWrapper = document.querySelector("#aboutsecond");

    gsap.fromTo(
      aboutSecondWrapper,
      { y: "+=125", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
        duration: 0.7,
        ease: "easeInOut",
        scrollTrigger: {
          trigger: aboutSecondWrapper,
          start: "-10% 76%",
        },
      }
    );
  });
  return (
    <StyledHistory id="aboutsecond">
      <div>
        <StyledLeft id="textWrapper">
          <h2>Kilka słów o mnie</h2>
          <p>
            Moim głównym zainteresowaniem jest psychologia żałoby. Ukończyłam
            szereg szkoleń psychologiczno-pedagogicznych z zakresu tematyki
            żałoby, między innymi szkolenie z terapii NEST, interwencji
            kryzysowej, prowadzenia grup wsparcia i pierwszego kontaktu.
            Dodatkowo jestem szkoleniowcem na terenie całej Polski z zakresu
            tematyki żałoby i procesów żałoby. Jestem również ambasadorką
            Fundacji "Dr Clown". Uczestniczyłam w konferencjach poświęconych
            tematyce straty, żałoby i śmierci, m.in. :
          </p>
          <ul>
            <li>
              XXI Piekarskie Sympozjum Naukowym poświęconym zagadnieniu: DOM, W
              KTÓRYM RODZI SIĘ WSPÓLNOTA – RODZINA, SPOŁECZEŃSTWO, KOŚCIÓŁ.
              Katowice
            </li>
            <li>
              Konferencja z okazji Dnia Świętości Życia pt. „Żyją w Bogu” - w
              trosce o dzieci zmarłe przed narodzeniem i ich rodziców. Płońsk
            </li>
            <li>
              IX Międzynarodowa Konferencja Naukowo-Szkoleniowa Życiodajna
              Śmierć - Pamięci Elizabeth Kübler-Ross. Białystok
            </li>
            <li>
              I Ogólnopolska Interdyscyplinarna Konferencja Naukowa "Utrata i
              żałoba - Teoria i praktyka" Katowice
            </li>
          </ul>
          <button className="button" onClick={() => scrollTo("#cards")}>
            Sprawdź ofertę
          </button>
        </StyledLeft>
        <StaticImage
          src="../../static/olga-komarnicka.jpg"
          alt="Olga Komarnicka"
          layout={"fullWidth"}
          placeholder={"none"}
          loading={"lazy"}
        />
      </div>
    </StyledHistory>
  );
}
